var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showLoaderComp)?_c('Loader',{staticClass:"mt-6 flex justify-center"}):_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"mt-6 w-full hidden md:block lg:hidden justify-center items-center"},[_c('img',{staticClass:"h-72 m-auto",attrs:{"src":require("@/assets/images/perpetual-logo.svg"),"alt":"perpetual-logo"}})]),_c('HeaderGlobal',{attrs:{"titlemenu1":"Notifications"}}),_c('div',{staticClass:"w-full mt-2 pl-0 pt-8 pb-8 text-left font-proximaMedium"},[_c('ul',_vm._l((_vm.feeds),function(item,i){return _c('li',{key:i,staticClass:"text-white font-proximaMedium px-4 py-4 my-1 relative",class:{
          'rounded-t-2xl': i === 0,
          'rounded-b-2xl': i === _vm.feeds.length - 1,
          'bg-orange': item.clicked === false,
          'bg-white': item.clicked === true,
          'text-subheaderText': item.clicked === true,
        }},[_c('h2',{staticClass:"text-lg pb-1"},[_vm._v(_vm._s(item.text))]),_c('TimeAgoVue',{staticClass:"text-xs font-proximaLight",attrs:{"date":item.date}}),(item.confirmation)?_c('div',{staticClass:"flex justify-center"},[_c('submit-button',{staticClass:"mr-1",attrs:{"title":"Decline","background":"bg-red","width":"w-4/12","paddingX":"px-2"}}),_c('submit-button',{staticClass:"ml-1",attrs:{"title":"Accept","background":"bg-success","width":"w-4/12","paddingX":"px-2"}})],1):_vm._e(),(item.confirmed != '')?_c('BorderedButtonVue',{staticClass:"mx-auto",attrs:{"title":item.confirmed,"width":"w-4/12","paddingX":"px-2","borderColor":item.confirmed === 'Accepted' ? 'border-primary' : 'border-error',"textCl":item.confirmed === 'Accepted' ? 'text-primary' : 'text-error'}}):_vm._e(),(item.redirectUrl != '')?_c('ArrowNext',{staticClass:"absolute right-4 top-7",attrs:{"height":24,"width":24,"iconColor":item.clicked ? '#354E57' : '#fafafa'}}):_vm._e()],1)}),0)]),_c('div',{staticClass:"w-full"},[_c('submit-button',{staticClass:"mx-auto",attrs:{"title":"Adjust Notification Settings","fontSize":"text-md","background":"bg-primary","width":"w-max"},nativeOn:{"click":function($event){return _vm.$router.push('/notificationsettings')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }