<template>
  <div>
    <p>{{ timeAgo }}</p>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    timeAgo() {
      const now = new Date();
      const pastDate = new Date(this.date);
      const seconds = Math.floor((now - pastDate) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(seconds / 3600);
      const days = Math.floor(seconds / 86400);

      if (seconds < 60) return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
      if (minutes < 60) return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      if (hours < 24) return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    },
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
