<template>
  <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" />
  <div v-else class="flex flex-col justify-center items-center">
    <div
      class="mt-6 w-full hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal titlemenu1="Notifications" />
    <!-- v-if="petoverviewTab != 1 || petTab1.length > 0" -->
    <div
      class="w-full mt-2 pl-0 pt-8 pb-8 text-left font-proximaMedium"
    >
      <ul>
        <li
          v-for="(item, i) in feeds"
          :key="i"
          class="text-white font-proximaMedium px-4 py-4 my-1 relative"
          :class="{
            'rounded-t-2xl': i === 0,
            'rounded-b-2xl': i === feeds.length - 1,
            'bg-orange': item.clicked === false,
            'bg-white': item.clicked === true,
            'text-subheaderText': item.clicked === true,
          }"
        >
          <h2 class="text-lg pb-1">{{ item.text }}</h2>
          <!-- <h5 class="text-xs font-proximaLight">{{ item.date }}</h5> -->
          <TimeAgoVue :date="item.date" class="text-xs font-proximaLight" />
          <div v-if="item.confirmation" class="flex justify-center">
            <submit-button
              class="mr-1"
              title="Decline"
              background="bg-red"
              width="w-4/12"
              paddingX="px-2"
            />
            <submit-button
              class="ml-1"
              title="Accept"
              background="bg-success"
              width="w-4/12"
              paddingX="px-2"
            />
          </div>
          <BorderedButtonVue
            v-if="item.confirmed != ''"
            :title="item.confirmed"
            width="w-4/12"
            paddingX="px-2"
            :borderColor="
              item.confirmed === 'Accepted' ? 'border-primary' : 'border-error'
            "
            :textCl="
              item.confirmed === 'Accepted' ? 'text-primary' : 'text-error'
            "
            class="mx-auto"
          />
          <ArrowNext
            v-if="item.redirectUrl != ''"
            class="absolute right-4 top-7"
            :height="24"
            :width="24"
            :iconColor="item.clicked ? '#354E57' : '#fafafa'"
          />
        </li>
      </ul>
    </div>
    <div class="w-full">
      <submit-button
        class="mx-auto"
        title="Adjust Notification Settings"
        fontSize="text-md"
        background="bg-primary"
        width="w-max"
        @click.native="$router.push('/notificationsettings')"
      />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import FormError from "../warning/FormError.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButtonVue from "../buttons/BorderedButton.vue";
import Loader from "../loader/Loader.vue";
import ArrowNext from "../icons/ArrowForwardIos.vue";
import TimeAgoVue from "../timeago/TimeAgo.vue";

export default {
  components: {
    FormError,
    HeaderGlobal,
    Loader,
    SubmitButton,
    BorderedButtonVue,
    ArrowNext,
    TimeAgoVue,
  },
  data() {
    return {
      feeds: [
        {
          no: 1,
          text: "Blood Donor: Ashra could help!",
          date: "2024-09-30T02:00:00Z",
          clicked: false,
          confirmation: false,
          confirmed: "",
          redirectUrl: "/petowner",
        },
        {
          no: 2,
          text: "Jana shared Pippa's record with you!",
          date: "2024-09-29T12:00:00Z",
          clicked: false,
          confirmation: false,
          confirmed: "",
          redirectUrl: "",
        },
        {
          no: 3,
          text: "Jake The Ripper wants to connect",
          date: "2024-09-29T10:00:00Z",
          clicked: true,
          confirmation: true,
          confirmed: "",
          redirectUrl: "",
        },
        {
          no: 4,
          text: "Jake The Ripper wants to connect",
          date: "2024-09-28T10:00:00Z",
          clicked: true,
          confirmation: false,
          confirmed: "Accepted",
          redirectUrl: "",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
